import { NgModule, isDevMode } from '@angular/core';

import { BrowserModule,HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { GlobalService } from './services/global.service';
import { UserService } from './services/user.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { LinkyModule } from 'ngx-linky';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import * as Hammer from 'hammerjs';

import {
  faHandshake,
  faHouse,
  faRunning,
  faBullhorn,
  faCalendar,
  faCalendars,
  faRightFromBracket,
  faArrowUpArrowDown,
  faBarcode,
  faCircleInfo,
  faBookOpen,
  faPenToSquare,
  faImages,
  faTrophy,
  faAward,
  faSquarePollHorizontal,
  faHandshakeAngle,
  faNewspaper,
  faShop,
  faMessageArrowUpRight,
  faIdCard,
  faChartLineUp,
  faCircleQuestion,
  faGears,
  faChevronCircleRight,
  faHeart,
  faTrash,
  faComment,
  faComments,
  faPlus,
  faCamera,
  faShareNodes,
  faMapLocation,
  faGlobe,
  faCalendarPlus,
  faCalendarUsers,
  faCalendarStar,
  faLocationPin,
  faMale,
  faFemale,
  faPeople,
  faUsers,
  faClose,
  faFilePdf,
  faFile,
  faBellRing,
  faLink,
  faMessageLines,
  faBallotCheck,
  faPollPeople,
  faFloppyDisk,
  faCircleUser,
  faChevronDown,
  faSnowflake,
  faMoneyBillWave,
  faPhone,
  faDownload,
  faFilter,
  faThumbsUp,
  faThumbsDown,
  faHatWinter,
  faTicket,
  faTshirt,
  faMug,
  faUtensils,
  faShoppingBasket,
  faWatchFitness,
  faBug,
  faHandBackPointUp,
  faLeft,
  faRight,
  faWavePulse,
  faPersonToPortal,
  faPersonFromPortal,
  faMoon,
  faTowerCell,
  faRepeat,
  faWallet,
  faListCheck,
  faPersonChalkboard,
  faFileShield,
  faListUl,
  faCodeBranch,
  faSignalStream,
  faFolderTree,
  faCalendarCheck,
  faCalendarCircleUser,
  faPeopleRoof,
  faBoxOpenFull,
  faDiagramPredecessor,
  faStopwatch,
  faRotateRight,
  faEnvelope,
  faRoute,
  faMinus,
  faList,
  faChevronLeft,
  faChevronRight,
  faEye,
  faBars,
  faPeopleLine,
  faThumbTack,
  faSun,
  faFolder,
  faArrowsSpin,
  faMessagePen,
  faBadgePercent,
  faSearchLocation,
  faChevronsDown,
  faPenNib,
  faPaperPlaneTop,
  faMemoPad,
  faBlockQuestion,
  faBagsShopping,
  faRectangleHistory,
  faCloudArrowDown,
  faCreditCard,
  faNotesMedical,
  faArrowsRotate
  
} from '@fortawesome/pro-light-svg-icons'
import{
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9
}from '@fortawesome/pro-regular-svg-icons';

import{
  faFlagCheckered,
  faHeart as faDHeart,
  faThumbTack as faDThumbTack,
  faTriangle
  
}from '@fortawesome/pro-duotone-svg-icons';

import{ faAndroid, faApple, faGithub, faInstagram, faStrava, faXTwitter, faTwitter, faFacebook, faAppStore, faGooglePlay, faTiktok }from '@fortawesome/free-brands-svg-icons';
import { ServiceWorkerModule } from '@angular/service-worker';

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { direction: Hammer.DIRECTION_ALL },
  };
}


@NgModule({
    declarations: [AppComponent,
    ],
    imports: [
        BrowserModule, 
        IonicModule.forRoot({
            // mode: 'ios',
            statusTap: true,
            hardwareBackButton: true
        }),
        AppRoutingModule,
        FontAwesomeModule,
        LinkyModule,
        HammerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }) ],
    exports: [],
    providers: [
         {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: HammerConfig
        },
        GlobalService,
        UserService,
        Calendar,
        GoogleMapsModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary
              ) {
                library.addIcons(
                    faMoon,
                    faHandshake,
                    faHouse,
                    faRunning,
                    faBullhorn, 
                    faCalendar, 
                    faCalendars, 
                    faCalendarPlus,
                    faCalendarUsers,
                    faLink,
                    faBellRing,
                    faCalendarStar,
                    faRightFromBracket,
                    faArrowUpArrowDown,
                    faBarcode,
                    faCircleInfo,
                    faBookOpen,
                    faPenToSquare,
                    faImages,
                    faTrophy,
                    faAward,
                    faSquarePollHorizontal,
                    faHandshakeAngle,
                    faNewspaper,
                    faShop,
                    faComments,
                    faMessageArrowUpRight,
                    faIdCard,
                    faChartLineUp,
                    faCircleQuestion,
                    faGears,
                    faChevronCircleRight,
                    faHeart,
                    faTrash,
                    faComment,
                    faPlus,
                    faCamera,
                    faDHeart,
                    faShareNodes,
                    faMapLocation,
                    faGlobe,
                    faLocationPin,
                    faMale,
                    faFemale,
                    faPeople,
                    faUsers,
                    faClose,
                    faFacebook,
                    faTiktok,
                    faInstagram,
                    faStrava,
                    faTwitter,
                    faXTwitter,
                    faAppStore,
                    faGooglePlay,
                    faFilePdf,
                    faTriangle,
                    faMessageLines,        
                    faBallotCheck,
                    faPollPeople,
                    faPaperPlaneTop,
                    faFloppyDisk,
                    faCircleUser,
                    faChevronDown,
                    faSnowflake,
                    faMoneyBillWave,
                    faPhone,
                    faDownload,
                    faFilter,
                    faFlagCheckered,
                    faThumbsUp,
                    faThumbsDown,
                    faHatWinter,
                    faTicket,
                    faTshirt,
                    faMug,
                    faUtensils,
                    faShoppingBasket,
                    faWatchFitness,
                    faBug,
                    faHandBackPointUp,
                    faLeft,
                    faRight,
                    faWavePulse,
                    faPersonToPortal,
                    faPersonFromPortal,
                    faTowerCell,
                    faRepeat,
                    faWallet,
                    faListCheck,
                    faPersonChalkboard,
                    faFileShield,
                    faListUl,
                    faCodeBranch,
                    faSignalStream,
                    faFolderTree,
                    faArrowsSpin,
                    faChevronsDown,
                    faGithub,
                    faCalendarCheck,
                    faCalendarCircleUser,
                    faPeopleRoof,
                    faBoxOpenFull,
                    faPenNib,
                    faDiagramPredecessor,
                    faStopwatch,
                    faAndroid,
                    faApple,
                    faRotateRight,
                    faArrowsRotate,
                    faEnvelope,
                    faRoute,
                    faMinus,
                    faList,
                    faEye,
                    faBars,
                    faChevronLeft,
                    faChevronRight,
                    faMoon,
                    faPeopleLine,
                    faThumbTack,
                    faDThumbTack,
                    faSun,
                    faFolder,
                    faMessagePen,
                    faBadgePercent,
                    faSearchLocation,
                    faMemoPad,
                    faBlockQuestion,
                    faBagsShopping,
                    faRectangleHistory,
                    faCloudArrowDown,
                    faCreditCard,
                    faNotesMedical,
                    fa0,
                    fa1,
                    fa2,
                    fa3,
                    fa4,
                    fa5,
                    fa6,
                    fa7,
                    fa8,
                    fa9,
                    
              );
  
  }

  
  

  
}