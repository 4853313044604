import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'hammerjs';


// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

enableProdMode();
/*console.log('environment: ')
console.log(environment)*/
if (environment.production) { 
  

  if (window) {
     window.console.log = function () { };
     window.console.info = function () { };
  }

}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => defineCustomElements(window))
  .catch(err => console.log(err));

