import { Component, OnInit,  OnDestroy, ViewChild, ViewEncapsulation, NgZone, HostListener, ChangeDetectorRef, ElementRef   } from '@angular/core';

import { NavigationEnd, Router  } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { AlertController, IonContent,  ModalController, NavController, Platform } from '@ionic/angular';
import { GlobalService } from "./services/global.service";
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
//import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { initializeApp } from "firebase/app";
import { environment } from "src/environments/environment";
//import { AdMob, AdOptions, MaxAdContentRating } from '@capacitor-community/admob';
import { Badge } from '@capawesome/capacitor-badge';
//import { StatusBar, Style } from '@capacitor/status-bar';
import {
  FirebaseMessaging
} from "@capacitor-firebase/messaging";


import version from '../../package.json';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import { TrainingService } from './services/training.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class AppComponent  implements OnInit, OnDestroy  {

  public token = "";
  cmc_url

  private subscriptions = new Subscription();
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('body') appBody: ElementRef;

  subscriptionclubSubscription = new Subscription();
  subscriptionClubID = new Subscription();
  clubSubscribed
  sideBarMenu: any
  readySteady
  when = false
  sideBarMenuSubscription = new Subscription();
  readySteadySubscription = new Subscription();
  closed$ = new Subject<any>();
  showTabs = true; // <-- show tabs by default
  appName: string = this.globalService.appName;

  club_id:any;
  jwttoken
  user_id;
  isLoggedIn = false;
  isAuthenticated = false;
  hasMultiClubs = false;
  isHome = false;
  primary_color:string;
  secondary_color:string;
  tertiary_color:string;
  public pageTitle: string;
  stripeKey

  appVersion = "web"
  appType = "web"
  appPlatform =  "web"
  deviceOS
  showMembershipForm = new Subscription();
  showingNewMembershipForm = false

  pushPermission;
stravaConnected = false
garminConnected = false



apiURL = this.globalService.apiURL;

  isApp = true
  darkMode = false
  preferWheel = false



newAnnouncementsubscription = new Subscription();
newMessageBoardsubscription = new Subscription();
newDMsubscription = new Subscription();
newGroupMsgsubscription = new Subscription();
newOfferSubscription = new Subscription();
isAuthenticatedSubscription = new Subscription();

newAnnouncement: boolean = false
newMessageBoard: boolean = false
newDM: boolean = false
newGroupMsg:boolean = false
newOffer:boolean = false


  constructor(private router: Router,
            public globalService: GlobalService,
            public storage: StorageService,
            private userService: UserService,
            private trainingService: TrainingService,
            private platform: Platform,
            private zone: NgZone,
            public alertController: AlertController,
            private navController: NavController,
            private modalController: ModalController,
            ) 
            { 


  this.initialiseApp() 
  this.initializeFirebase();

                 /* web based push notifications */

     // if (Capacitor.getPlatform() === "web") {

        this.globalService.checkWebPushPermissions()

              FirebaseMessaging.addListener("notificationReceived", (event) => {
             //   console.log("notificationReceived: ", { event });

                let pushTitle = event.notification.title;
                let pushMsg = event.notification.body;
                let url = event.notification.data['cmc_url'];
           
                this.globalService.notificationData = event
              
                if(url.includes("message-board")){
                  this.userService.newMessageBoard.next(true);
                }
            
                if(url.includes("direct-message")){
                  this.userService.newDM.next(true); 
                }
            
                if(url.includes("announcements")){
                  this.userService.newAnnouncement.next(true);
                }
            
                if(url.includes("group-chat")){
                  this.userService.newGroupMsg.next(true);
                }

                if(url.includes("training-group")){
                  this.trainingService.getTrainingSessions();
                }
            
                let message = pushTitle + ' - ' + pushMsg
                let position = "top";
                this.globalService.presentToast(message,5000, position)
 

              });
              
              FirebaseMessaging.addListener("notificationActionPerformed", async (event) => {
             //   console.log("notificationActionPerformed: ", { event });

            //   console.log(event.notification)
               
                let url = event.notification.data['cmc_url'];
                this.cmc_url = url

               /* let newClubID = event.notification.data['club_id']
                let newClub = false
                if( this.club_id != newClubID ){
                  newClub = true
                }
                     
                if(newClub){
                  this.getNewClubDetails(newClubID)
                return false;
                }*/
            
                this.navController.navigateRoot(url);
                
              });
             
              if (Capacitor.getPlatform() === "web") {
                navigator.serviceWorker.addEventListener("message", (event: any) => {
             console.log("serviceWorker message: ", { event });
                  const notification = new Notification(event.data.notification.title, {
                    body: event.data.notification.body,
                  });

                  let url = event.data.data.cmc_url
                  this.cmc_url = url


                if(url.includes("message-board")){
                  this.userService.newMessageBoard.next(true);
                } 
                if(url.includes("direct-message")){
                  this.userService.newDM.next(true); 
                }
                if(url.includes("announcements")){
                  this.userService.newAnnouncement.next(true);
                }
                if(url.includes("group-chat")){
                  this.userService.newGroupMsg.next(true);
                }

               /* let newClub = false
                let newClubID = event.data.data.club_id
                console.log("newClubID" + newClubID)
                console.log("CLUB ID" + this.club_id)
                if( this.club_id != newClubID){

                  newClub = true
           

                }*/


                  notification.onclick = (event) => {
console.log(event)
                    /*  if(newClub){
                        this.getNewClubDetails(newClubID)
                          
                       

                         return false;
                      }*/

                    this.navController.navigateRoot(url);
                  };
                });
              }
              

    //   this.getAppDetails();

              App.addListener('backButton', async ({canGoBack}) => {

                let modalOpen =  await this.modalController.getTop();

             //   console.log("MODAL OPEN" + modalOpen)

                    if( modalOpen){
                      this.modalController.dismiss() 
                      //return false;
                      } 
                    else if(!canGoBack){
                      App.exitApp();
                    } else {

                      this.navController.back();
                      console.log("GOING BACK")
                    }
              });

              App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {

             
                //   console.log(event.url)
                     // Example url: https://mobile.connectmyclub.co.uk/app/tabs/tab2
                     // slug = /tabs/tab2
 
                     const domain = 'https://members.connectmyclub.co.uk';
                     const pathArray = event.url.split(domain);
                     // The pathArray is now like ['https://devdactic.com', '/details/42']
             
                     // Get the last element with pop()
                     const appPath = pathArray.pop();

                  //   console.log(appPath)
                     
                     if (appPath) {
                       this.router.navigateByUrl(appPath);
                     }

                     this.setDarkMode()
                    setTimeout(async () => { 
                      this.usageStats('Open URL')
                  }, 1000)
                     // If no match, do nothing - let regular routing
                     // logic take over
                // });
             });
             
                  /* app restore 
        
                  App.addListener('appRestoredResult', async data => {
                //  console.log('Restored state:', data);

                  
            setTimeout(async () => { 
              this.usageStats('getting 2')
          }, 1000)
                 
                  });
        */

                  /* This happens when the app changes state */

                App.addListener('appStateChange', async ({ isActive }) => {

       //        console.log('App state changed. Is active?', isActive);
   
                    if(isActive){
                      this.setDarkMode()
                        setTimeout(async () => { 
                                              this.usageStats('CHANGE STATE')
                                          }, 1000)
                    }
                     
                    })
                    
                    

                
                Network.addListener('networkStatusChange', status => {
                 // alert('Network status changed ' + status.connected);

                  if( !status.connected ){

                  let message = "Internet connection lost";
                  let length = 3000
                  let position = "bottom"
                  this.globalService.presentToast(message, length, position)

                  }
                });
       

      this.isAuthenticatedSubscription =   this.globalService.isAuthenticated.subscribe( async value => {
              
              /* Hide menu and tabs untils user is logged in. */
              this.isAuthenticated = false;

             //  this.getAppDetails()

           //  console.log("IS AUTHENICATED " + value)

               /* if user is already authenticated then get club details from storage */
            if( value == true){ 
              this.isAuthenticated = true;

              let user = await this.userService.getUser();
              
                  /* if a club _id is stored then we're ok to run the app */
              if( user['club_id'] >= 0 ){

              //  console.log(user['club_id'])
                this.isLoggedIn = true;
                this.appName = user['club_name']; 
                this.user_id = user['user_id'];
                this.club_id = user['club_id'];


                this.primary_color =  user['colors']['primary'];


                if( user['multiClubs'] == 1 ){
                  this.hasMultiClubs = true;
                }
                this.showTabs = true; 
             //   this.router.navigate(['app/tabs/home'], { replaceUrl: true });
                
              }

            }else{
             
              /* user not logged in so reset app colours and name */
              this.appName = this.globalService.appName; 

              this.primary_color = this.globalService.primary_color;
              document.documentElement.style.setProperty(`--ion-color-primary`, this.primary_color); 

              this.secondary_color = this.globalService.primary_color;
              document.documentElement.style.setProperty(`--ion-color-secondary`, this.secondary_color); 

              this.tertiary_color = this.secondary_color;
              document.documentElement.style.setProperty(`--ion-color-tertiary`, this.tertiary_color); 

              this.primary_color = this.globalService.primary_color_hex
            } 

            });

 
            
            this.newAnnouncementsubscription = this.userService.newAnnouncement.subscribe((data) => {
           //   console.log("ANNOUNCEMENT"+data)
              newAnnouncement: data
              this.updateNotificationReceived("announcements",data)
            });
            this.newMessageBoardsubscription = this.userService.newMessageBoard.subscribe((data) => {
              this.updateNotificationReceived("message-board",data)
            });
             this.newDMsubscription = this.userService.newDM.subscribe((data) => {
              this.updateNotificationReceived("direct-message",data)
            });
            this.newGroupMsgsubscription = this.userService.newGroupMsg.subscribe((data) => {
              this.updateNotificationReceived("group-chat",data)
            });
            this.subscriptionclubSubscription = this.userService.clubSubscription.subscribe((data) => {
              this.clubSubscribed = data
          });
   
          this.newOfferSubscription = this.userService.newOffer.subscribe((data) => {
            //console.log("SUBSCRIBED " + data)
            this.newOffer = data
        });

   
        this.showMembershipForm = this.userService.showMembershipForm.subscribe((data) => {
   
          this.showingNewMembershipForm = data 
   
        //  console.log("SHOWING " + this.showingNewMembershipForm)
       
         }) 
   
         this.sideBarMenuSubscription = this.userService.sideBarMenu.subscribe(async (data) => {
          // console.log("SIDEBAR DATA " + data)
           if(data){
             this.sideBarMenu = data
          //   console.log("SIDEBAR MENU " + this.sideBarMenu)
           }/*else{
             this.userService.getUser()
           }*/
          }) 
   
          this.readySteadySubscription = this.userService.readySteady.subscribe((data) => {
             this.readySteady = data
    
        console.log(data)
          })
   
          this.subscriptionClubID = this.userService.clubID.subscribe((data) => {
           this.club_id = data
           console.log(data)
       });
  

            this.stripeKey = this.globalService.stripeKey;
            }
  
            public async initializeFirebase(): Promise<void> {
              initializeApp(environment.firebase);
            }
            
            async initialiseApp(){

             

          this.platform.ready().then(async () => {

                setTimeout(async () => {
                    SplashScreen.hide();
                    this.usageStats('START')  
                    this.setDarkMode()
                      }, 1100)

                    
              /**
       * initialize() require after platform.ready();
       
      AdMob.initialize({
        requestTrackingAuthorization: false,
        testingDevices: ['64867960-4298-482f-b554-826025df15eb'],
        initializeForTesting: true,
        maxAdContentRating: MaxAdContentRating['General']
      });
*/

                      });
            }


            updateNotificationReceived(type,state){

              if( type == "message-board") {
                this.zone.run( () => {
                  this.newMessageBoard = state
                })
              }
              if( type == "direct-message") {
                this.zone.run( () => {
                this.newDM = state
              })
              }
              if( type == "announcements") {
              this.zone.run( () => {
                this.newAnnouncement = state
            })
              }
              if( type == "group-chat") {
                this.zone.run( () => {
                this.newGroupMsg = state
              })
              }

              
            }

            

            async getNewClubDetails( clubID ) {

              let dataString = {'new_club_id':clubID}

               const sub =    (await this.globalService.request('post', false, 'ionic_login', dataString)).subscribe(
                     
                   async (res) => {
             console.log(res)
                // console.log(getting)
                     if( !res){ return;}


                     let clubCount = res.clubs.length;
                     let clubsArr = res.clubsArr;
                     let multiClubs = false
                     if( res.clubs.length > 1 ){
                        multiClubs = true
                     }
                 let newToken = res.jwttoken
                     this.clubSelection(clubID,clubsArr);

                //   console.log(newToken)
                     await this.storage.setString('jwttoken', newToken);
                   });
             
                   this.subscriptions.add(sub)
             };

             async clubSelection(clubID,clubsArr){

              let clubDetails = [];
              let userDetails = [];

              this.club_id = clubID

              this.userService.clubID.next( this.club_id )

                /* store club specific details ar object */
                clubDetails = clubsArr[clubID];
          
                console.log(clubDetails)

                let multiClubs = "0"
                     if( clubsArr.length > 1 ){
                        multiClubs = "1"
                     }
          
                clubDetails['multiClubs'] =  multiClubs ;
                
          
                let clubDetailsjson = JSON.stringify(clubDetails) 
          
                console.log(clubDetailsjson)
          
                this.storage.setString('clubDetails', clubDetailsjson);
          
                let club_name = clubDetails['club_name'];
          
               let membership_new_members = clubDetails['membership_new_members']
               let membership_form_completed = clubsArr[clubID].membership_form_completed;
          
                /* store user specific details */
                let user_id = clubsArr[clubID].user_id;
                let jwttoken =  clubsArr[clubID].jwttoken;

                console.log(jwttoken)
               await this.storage.setString('user_id', user_id);
               await this.storage.setString('jwttoken', jwttoken);
               await this.storage.setString('multiClubs', multiClubs);
          
                
                this.globalService.updateClubName(club_name);
          
                this.userService.userDetails['user_id'] = user_id;
                this.userService.userDetails['club_id'] = clubID;
                this.userService.userDetails['membership_form_completed'] = membership_form_completed;
                this.userService.userDetails['jwttoken'] = jwttoken;
                this.userService.userDetails['multiClubs'] = multiClubs;
          /**/
                this.globalService.isAuthenticated.next(true);
          
                this.globalService.isLoggedIn.next(true);
          
                this.globalService.checkWebPushPermissions()
                /* redirect to membership form if required by the club */
          /*
                if( membership_new_members == true && membership_form_completed == false && clubID > 0){
                  this.userService.showMembershipForm.next(true);
                  this.router.navigate(['app/tabs/membership'], { replaceUrl: true });
                } else{
                  this.userService.showMembershipForm.next(false);
               }
*/
                     
              setTimeout(async () => { 
                console.log("SWITCHING CLUBS")
                    this.router.navigate([this.cmc_url], { replaceUrl: true });
                 }, 10)
            }


     async ngOnInit() {


      this.isApp = this.globalService.isApp


      

     this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.closed$)
      ) .subscribe((event: any) => {

      });

 
      
 }


/*
 async getAppDetails(){

  console.log("GETTING APP DETAILS")

  await Device.getInfo().then(deviceInfo => {
    this.appPlatform =  deviceInfo.platform;
    this.deviceOS = deviceInfo.operatingSystem;
  });
  if( this.isApp ){
    await App.getInfo().then(appInfo => {
      this.appVersion = appInfo.version;
      this.appType = appInfo.name
    });
  }
 else if( !this.isApp ){
      this.appVersion = version.version
  }
  return true;
 }*/


 async setDarkMode(){

  await this.storage.getString('dark').then(async (data: any) => {
  
  let darkMode = data
  let isDarkMode = darkMode.value

    //("DARK MODE STORAGE: " + isDarkMode)

    if(isDarkMode === 'true'){

   //   console.log("adding dark mode")
      document.body.classList.add('dark')
    }

    if(isDarkMode === 'false'){

   //   console.log("removing dark mode")
      document.body.classList.remove('dark')
    }
    
  });

}


 ScrollToTop(el) {
  var element = document.getElementById(el);
    this.content.scrollToPoint(0, element.offsetTop, 200);
}

/*
 async setFontSize(){

  await this.storage.getString('zoom-level').then(async (data: any) => {
 
  let zoom = "1.0"
    if( data.value ){
     zoom = data.value
    }
    if( data.value == "1.4"){
      zoom = "1.3";
     }
  let currentZoom = parseFloat(zoom)
  let options:SetOptions={
    value : currentZoom
  }

  await TextZoom.set(options)

})

}*/
/*
async addListeners() {

 


  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);
    
    let pushToken = token.value

    this.globalService.registerToken(pushToken)

  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', async notification => {
    console.log('Push notification received: ', notification);

    let pushTitle = notification.data.title;
    let pushMsg = notification.data.body;
    let url = notification.data.cmc_url;

    this.globalService.notificationData = notification
  
    if(url.includes("message-board")){
      this.userService.newMessageBoard.next(true);
    }

    if(url.includes("direct-message")){
      this.userService.newDM.next(true); 
    }

    if(url.includes("announcements")){
      this.userService.newAnnouncement.next(true);
    }

    if(url.includes("group-chat")){
      this.userService.newGroupMsg.next(true);
    }

    let message = pushTitle + ' - ' + pushMsg
    let position = "top";
    this.globalService.presentToast(message,5000, position)

  });

  await PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
    console.log(notification);

   
    let url = notification.notification.data.cmc_url

    this.navController.navigateRoot(url);
  });

   

}

async registerNotifications(){
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}
*/





async usageStats( getting ) {

  
 //  setTimeout(async () => { 
                if ( Capacitor.isNativePlatform() ){


                
     //   const clear = async () => {
          await Badge.clear();
      //  };

        //console.log('CLEAR ' + clear)
      // const removeAllDeliveredNotifications = async () => {
            await FirebaseMessaging.removeAllDeliveredNotifications();
      //    };

       //   console.log('REMOVE ' + removeAllDeliveredNotifications)
          
        //  }, 500) 
}


if (location.host !== "localhost:8100"){
  const sub =    (await this.globalService.request('post', false, 'ionic_postUsageStats', null)).subscribe(
          
        async (res) => {

    // console.log(getting)
          if( !res){ return;}

        let newToken = res.jwttoken

    //   console.log(newToken)
          await this.storage.setString('jwttoken', newToken);
        });

        this.subscriptions.add(sub)
}
  
};




  async logout() {

    this.alertController.create({
      header: 'Confirm logout',
      subHeader: '',
      message: '',
      buttons: [
        {
          text: 'Cancel',
          handler: (data: any) => {
            console.log('Canceled', data);
          }
        },
        {
          text: 'Confirm',
          handler: async (data: any) => {
            console.log('Loggin Out', data);
            await this.globalService.logout();
          }
        }
      ]
    }).then(res => {

      res.present(); 

    });

    
  }



    
    ngOnDestroy() {
      this.closed$.next(true); // <-- close subscription when component is destroyed
      this.showMembershipForm.unsubscribe();
      this.newAnnouncementsubscription.unsubscribe();
      this.newOfferSubscription.unsubscribe();
      this.newDMsubscription.unsubscribe();
      this.newMessageBoardsubscription.unsubscribe();
      this.isAuthenticatedSubscription.unsubscribe();
      this.subscriptionclubSubscription.unsubscribe();
      this.newGroupMsgsubscription.unsubscribe();
      this.sideBarMenuSubscription.unsubscribe();
      this.readySteadySubscription.unsubscribe();
      this.subscriptions.unsubscribe();
    }
    
}




